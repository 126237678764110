export default [
  {
      component: 'CNavItem', 
      name: 'Pos',
      to: '/',
      icon: 'cil-basket',
      visible: true,
      permision: ['Admin', 'Vendedor', 'Supervisor'],
      items: [
        {
          component: 'CNavItem',
          name: 'Nuevo Pedido',
          to: '/posi',
        },
        {
          component: 'CNavItem',
          name: 'Checador de Precios',
          to: '/cotizador',
        }
      ]
  },
  {
    component: 'CNavItem',
    name: 'Terminales',
    icon: 'cil-notes',
    visible: true,
    permision: ['Admin', 'Supervisor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Alta Terminal',
        to: '/altaTerminal',
      }
    ]
  },
  {
    component: 'CNavItem', 
    name: 'Usuarios',
    icon: 'cil-user',
    visible: true,
    permision: ['Admin', 'SuperAdmin'],
    items: [
      {
        component: 'CNavItem',
        name: 'Alta Usuario',
        to: '/altaUsuario',
      }
    ]
  },
  {
    component: 'CNavItem', 
    name: 'Sucursales',
    icon: 'cil-cursor',
    visible: true,
    permision: ['Admin', 'SuperAdmin'],
    items: [
      {
        component: 'CNavItem',
        name: 'Alta Sucursales',
        to: '/altaSucursales',
      }
    ]
  },
  {
    component: 'CNavItem', 
    name: 'Tipo Cambios',
    icon: 'cil-dollar',
    visible: true,
    permision: ['Admin', 'Supervisor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Alta cambios',
        to: '/altaCambio',
      }
    ]
  },
  {
    component: 'CNavItem', 
    name: 'Historico',
    icon: 'cil-list',
    visible: true,
    permision: ['Admin', 'Supervisor', 'Vendedor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Pedidos',
        to: '/historicoPedidos',
      }
    ]
  },
  {
    component: 'CNavItem', 
    name: 'Clientes',
    icon: 'cil-user',
    visible: true,
    permision: ['Admin', 'Supervisor', 'Vendedor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Clientes',
        to: '/clientes',
      }
    ]
  },
  {
    component: 'CNavItem', 
    name: 'Bases',
    icon: 'cil-dollar',
    visible: true,
    permision: ['Admin', 'Supervisor'],
    items: [
      {
        component: 'CNavItem',
        name: 'Bases',
        to: '/bases',
      }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Puntos',
    icon: 'cil-star',
    visible: true,
    permision: ['Admin',  'Supervisor'],
    items: [
       {
         component: 'CNavItem',
         name: 'Puntos',
         to: '/puntos'
       }
    ]
  },
  {
    component: 'CNavItem',
    name: 'Empresas',
    icon: 'cil-puzzle',
    visible: true,
    permision: ['SuperAdmin'],
    items: [
       {
         component: 'CNavItem',
         name: 'Empresas',
         to: '/empresas'
       },
       {
        component: 'CNavItem',
        name: 'Asignacion Empresas',
        to: '/asignacionEmpresas'
      }
    ]
  }
]
