import { get, pos, put } from "@/core/services/servicesPos";


export async function getRoles() {
    let  response = await get('/roles/obtenerRoles');
    return response;
}

export async function saveUsuarioPos(usuario)  {
     let response = await pos('/usuariosPos/altaUsuario', usuario);
     return response;
}

export async function getUsuarios() {
    let response =  await get('/usuariosPos/obtenerUsuarios');
    return response;
}

export async function getUsuariosAdmin() {
    let response =  await get('/usuariosPos/obtenerUsuariosAdmin');
    return response;
}

export async function asignarEmpresasUsuario() {
   let response = await pos('/usuariosPos/asignacionUsuarioEmpresas')
   return response
}

export async function getUsuaiosFilter(sucursales) {
    let response = await pos('/usuariosPos/obtenerUsuariosFilter', sucursales)
    return response
}

export async function updateUsuarioRol(idUsuario, rol) {
    let response = await put(`/usuariosPos/actualizarRolUsuario/${idUsuario}`, rol);
    return response;
}

export async function actulizarStatusUsuario(idUsuario, statusUsuario) {
    let response = await put(`/usuariosPos/actualizarStatusUsuarios/${idUsuario}`, statusUsuario); 
    return response;
}

export async function getUsuario(idUsuario) {
    let response = await get(`/usuariosPos/obtenerUsuario/${idUsuario}`); 
    return response;
}

export async function getUsuarioSinTerminal(idSucursal) {
    let response = await get(`/usuariosPos/obtenerUsuariosSinAsignacion/${idSucursal}`); 
    return response;
}

export async function getUsuarioSucursales(filterSucursales) {
    let response = await pos('/usuariosPos/usuarioSucursales', filterSucursales)
    return response
}